import React, { useState } from "react";
import axios from "axios";
import "./ContactUs.css";

const ContactUs = () => {
  const [state, setState] = useState({
    name: "",
    company: "",
    email: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState("");

  const handleChange = ({ target }) =>
    setState((prev) => ({
      ...prev,
      [target.name]: target.value,
    }));

  const handleSubmit = async (e) => {
    try {
      const url = "https://nestic-backend.onrender.com/api/mail";
      const { state: res } = await axios.post(url, state);
      alert("Message sent successfully");
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
  };
  return (
    <div className="contact-us">
      <h2>Send us a message</h2>
      <div className="contact-us-container">
        <div className="contact-info">
          <h2>Contact Us</h2>
          <h3>
            Fill up the form and our team will get back to you within 24 hours
          </h3>
          <div className="contact">
            <img src="call.svg" alt="icon representing calling" />
            <p>+234 70 1000 4741</p>
          </div>
          <div className="contact">
            <img src="email.svg" alt="icon representing email" />
            <p>quakehive@gmail.com</p>
          </div>
          <div className="contact">
            <img src="location.svg" alt="icon representing location" />
            <p>102 street A zone</p>
          </div>
          <div className="social-media">
            <a href="#">
              <img src="twitter.svg" alt="icon representing twitter" />
            </a>
            <a href="#">
              <img src="linkedin.svg" alt="icon representing linkedin" />
            </a>
            <a href="#">
              <img src="google.svg" alt="icon representing google" />
            </a>
          </div>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="input-row">
            <div className="input-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="company">Company:</label>
              <input
                type="text"
                id="company"
                name="company"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="input-row">
            <div className="input-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="phone">Subject:</label>
              <input
                type="text"
                id="subject"
                name="subject"
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="input-group width">
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              onChange={handleChange}
              required
            ></textarea>
          </div>
          {error && <div className="error_msg">{error}</div>}
          <button className="form-button">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
