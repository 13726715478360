import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import "./Nestic.css";
import Nav from "./main/Index";

function CsvToJsonConverter() {
  const [jsonData, setJsonData] = useState(null);
  const [denominator, setDenominator] = useState(null);
  const [state, setState] = useState("");
  const [copies, setCopies] = useState(0);

  // Cache element lookups
  const stateEl = document.querySelector("#state");
  const custodianEl = document.querySelector("#custodian");
  const custNumEl = document.querySelector("#cust_num");
  const schNameEl = document.querySelector("#sch_name");
  const schNumEl = document.querySelector("#sch_num");
  const subjectEl = document.querySelector("#subject");
  const inputEl = document.getElementById("input");
  const cellEl = document.getElementById("no");
  const myOmrEl = document.getElementById("omr");
  const dropbtnEl = document.querySelector(".dropbtn");
  const statusEl = document.querySelector(".text-line");

  // Dropdown button
  const subjectBtn = document.getElementById("subjectBtn");
  const subjectOptions = document.querySelectorAll(".subject-option");

  const subjects = [
    "eng",
    "fre",
    "ara",
    "hal",
    "igl",
    "yol",
    "bus",
    "irs",
    "crs",
    "edo",
    "efk",
    "ibb",
    "cca",
    "bst",
    "pvs",
    "nve",
  ];

  let selectedSubject = "";

  // Function to call when a subject is selected
  function selectSubject(event) {
    // Get the selected subject
    selectedSubject = event.target.dataset.subject;

    // Update the subject button text
    subjectBtn.textContent = selectedSubject.toUpperCase();

    // Call the find function with the selected subject
    // find(selectedSubject);
  }

  // Add click event listeners to subject options
  subjectOptions.forEach((option) =>
    option.addEventListener("click", selectSubject)
  );

  async function find() {
    if (dropbtnEl.textContent === "OMR") return alert("Please choose an OMR");
    if (denominator === null) return alert("Please add a denominator");

    try {
      // Delete all rows with id "newID"
      const rows = document.querySelectorAll("#newID");
      rows.forEach((row) => row.remove());

      for (const data of jsonData) {
        if (data.hasOwnProperty(selectedSubject)) {
          console.log(data.state);
          const state = data.state;
          const custodian = data.custodian;
          const custCode = data.cust_code;
          const schlName = data.schl_name;
          const schnum = data.schnum;

          // Update elements
          stateEl.textContent = state;
          custodianEl.textContent = custodian;
          custNumEl.textContent = custCode;
          schNameEl.textContent = schlName;
          schNumEl.textContent = schnum;
          subjectEl.textContent = selectedSubject;

          calc(parseInt(data[selectedSubject], 10));
        }
      }
    } catch (error) {
      console.log(error);
    }

    // Empty cache
    stateEl.textContent = "Done";
    custodianEl.textContent = "Done";
    custNumEl.textContent = "Done";
    schNameEl.textContent = "Done";
    schNumEl.textContent = "Done";
    subjectEl.textContent = "Done";
    inputEl.textContent = "";
    cellEl.textContent = "";
    myOmrEl.textContent = "";
    dropbtnEl.textContent = "OMR";
    statusEl.textContent = selectedSubject;
  }

  let operators = {
    "+": (a, b) => a + b,
    "-": (a, b) => a - b,
  };

  let op;

  function plus() {
    dropbtnEl.textContent = "+ 5";
    op = "+";
  }

  function minus() {
    dropbtnEl.textContent = "- 5";
    op = "-";
  }

  let count = 0;

  const calc = (amount) => {
    let wre = denominator;
    let bags = 0;

    while (amount >= wre) {
      amount = amount - wre;
      bags++;
    }

    if (amount < wre && amount > 0) {
      bags++;
    }

    while (count < bags) {
      count++;
      cellEl.textContent = wre;
      myOmrEl.textContent = wre;

      // show remainder
      if (count == bags && amount % 40 !== 0) {
        cellEl.textContent = amount;
        const result = operators[op](amount, 5);
        myOmrEl.textContent = result;
      }

      // Call cloneRow function while counting
      inputEl.textContent = count + " of " + bags;
      cloneRow();
    }
    // Refresh counter
    if (!count < bags) {
      count = 0;
      inputEl.textContent = count + " of " + bags;
    }
  };

  function cloneRow() {
    var i = 0;
    while (i < 10) {
      var row = document.getElementById(i); // find row to copy
      var table = document.getElementById("tableToModify"); // find table to append to
      var clone = row.cloneNode(true); // copy children too
      clone.id = "newID"; // change id or other attributes/contents
      table.appendChild(clone); // add new row to end of table
      i++;
    }
  }

  /* HTML to Microsoft Word Export Demo
   * This code demonstrates how to export an html element to Microsoft Word
   * with CSS styles to set page orientation and paper size.
   * Tested with Word 2010, 2013 and FireFox, Chrome, Opera, IE10-11
   * Fails in legacy browsers (IE<10) that lack window.Blob object
   */

  const exportFile = () => {
    if (!window.Blob || !window.ReadableStream) {
      alert("Your browser does not support this action.");
      return;
    }

    const html = window.docx.innerHTML;
    const css = `
    <style>
      @page WordSection1 {
        size: 841.95pt 595.35pt;
        mso-page-orientation: landscape;
      }
      div.WordSection1 {
        page: WordSection1;
      }
      table {
        border-collapse: collapse;
      }
      th {
        border: 1px gray solid;
        width: 10em;
        text-align: left;
        font-weight: bold;
        padding: 7px;
      }
      .wre {
        text-align: center;
      }
      td {
        border: 1px gray solid;
        width: 10em;
        text-align: left;
        padding: 7px;
      }
      .dash {
        text-align: center;
      }
    </style>
  `;
    const blob = new Blob(["\ufeff", css + html], {
      type: "application/msword",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Document";
    document.body.appendChild(link);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, "Document.doc");
    } else {
      link.click();
    }
    document.body.removeChild(link);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        setJsonData(result.data);
      },
    });
  };

  return (
    <div className="color">
      <Nav />
      <div>
        <h1>Script Co-ordinator</h1>
        <div className="check">
          <input type="file" onChange={handleFileUpload} />
          <div className="denominator">
            <p>Enter denominator:</p>
            <input
              id="in"
              type="text"
              name="input"
              onChange={(e) => setDenominator(e.target.value)}
              value={denominator}
            />
          </div>
          <div>
            <div className="dropdown">
              <button className="dropbtn">OMR</button>
              <div className="dropdown-content">
                <a onClick={plus}>+ 5</a>
                <a onClick={minus}>- 5</a>
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn" id="subjectBtn">
                Select Subject
              </button>
              <div className="dropdown-content">
                <a href="#" className="subject-option" data-subject="eng">
                  eng
                </a>
                <a href="#" className="subject-option" data-subject="fre">
                  fre
                </a>
                <a href="#" className="subject-option" data-subject="ara">
                  ara
                </a>
                <a href="#" className="subject-option" data-subject="hal">
                  hal
                </a>
                <a href="#" className="subject-option" data-subject="igl">
                  igl
                </a>
                <a href="#" className="subject-option" data-subject="yol">
                  yol
                </a>
                <a href="#" className="subject-option" data-subject="bus">
                  bus
                </a>
                <a href="#" className="subject-option" data-subject="irs">
                  irs
                </a>
                <a href="#" className="subject-option" data-subject="crs">
                  crs
                </a>
                <a href="#" className="subject-option" data-subject="edo">
                  edo
                </a>
                <a href="#" className="subject-option" data-subject="efk">
                  efk
                </a>
                <a href="#" className="subject-option" data-subject="ibb">
                  ibb
                </a>
                <a href="#" className="subject-option" data-subject="cca">
                  cca
                </a>
                <a href="#" className="subject-option" data-subject="bst">
                  bst
                </a>
                <a href="#" className="subject-option" data-subject="pvs">
                  pvs
                </a>
                <a href="#" className="subject-option" data-subject="nve">
                  nve
                </a>
              </div>
            </div>

            <button id="stuff" onClick={find}>
              Start
            </button>
            <button id="export" onClick={exportFile}>
              export
            </button>
          </div>
          <h2 className="text-line"></h2>
        </div>
        <div id="docx">
          <div id="WordSection1">
            <table className="container">
              <tbody id="tableToModify">
                <tr>
                  <th id="0" colSpan="4" className="wre" contentEditable="true">
                    NATIONAL EXAMINATION COUNCIL (NECO)
                    <br />
                    BECE 2023
                  </th>
                </tr>
                <tr id="1">
                  <th>STATE</th>
                  <td
                    id="state"
                    contentEditable="true"
                    colSpan="3"
                    onChange={(e) => setState(e.target.value)}
                  ></td>
                </tr>
                <tr id="2">
                  <th>CUSTODIAN NAME</th>
                  <td id="custodian" colSpan="3"></td>
                </tr>
                <tr id="3">
                  <th>CUSTODIAN NUMBER</th>
                  <td id="cust_num" colSpan="3"></td>
                </tr>
                <tr id="4">
                  <th>SCH/CENTER NAME</th>
                  <td id="sch_name" colSpan="3"></td>
                </tr>
                <tr id="5">
                  <th>SCH/CENTER NO</th>
                  <td id="sch_num" colSpan="3"></td>
                </tr>
                <tr id="6">
                  <th>SUBJECT/PAPER NAME</th>
                  <td id="subject" contentEditable="true" colSpan="3"></td>
                </tr>

                <tr id="7">
                  <th>NO. COPIES</th>
                  <td id="no" onChange={(e) => setCopies(e.target.value)}>
                    0
                  </td>
                  <td>NO. OF OMR</td>
                  <td id="omr" contentEditable="true">
                    0
                  </td>
                </tr>

                <tr id="8">
                  <th>PACK</th>
                  <td id="input" colSpan="3">
                    0 of 0
                  </td>
                </tr>

                <tr id="9">
                  <td className="dash" colSpan="4">
                    -------------------------------------------------------------
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CsvToJsonConverter;
