import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./LandingPage.css";
import Nav from "./nav/Nav.js";
import Hero from "./hero/Hero";
import Features from "./features/Features";
import HowItWorks from "./how-it-works/HowItWorks";
import PricingCard from "./price/PricingCard";
import ContactUs from "./contact/ContactUs";
import Footer from "./footer/Footer";

function LandingPage() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Printing Press Solutions for B2B companies</title>
          <meta
            name="description"
            content="We offer advanced printing press solutions for printing press companies to improve efficiency and reduce costs involved in the creation of NECO stickers. Our cutting-edge technology is tailored to meet the unique needs of the printing industry in Nigeria."
          />
          <meta
            name="keywords"
            content="printing press, B2B, solutions, efficiency, costs, technology, printing industry, NECO"
          />
        </Helmet>
        <header>
          <Nav />
        </header>
        <main>
          <section id="hero">
            <Hero />
          </section>
          <section id="features">
            <Features />
          </section>
          <section id="how-it-works">
            <HowItWorks />
          </section>
          <section id="pricing">
            <PricingCard />
          </section>
          <section id="contact-us">
            <ContactUs />
          </section>
        </main>
        <footer>
          <Footer />
        </footer>
      </HelmetProvider>
    </>
  );
}

export default LandingPage;
