import React from "react";
import "./Features.css";

const Features = () => {
  return (
    <div className="features">
      <h2>Key Features</h2>
      <p>Here are the main features of Nestic</p>
      <div className="feature-section">
        <div>
          <h3>Fast</h3>
          <p>
            Create stickers for thousands of NECO dockets in a matter of
            minutes.
          </p>
          <img src="running.png" alt="Feature 1 illustration" />
        </div>
        <div>
          <h3>Affordable</h3>
          <p>
            Nestic gives you all the functionality for less than half the price.
          </p>
          <img src="counting.png" alt="Feature 2 illustration" />
        </div>
        <div>
          <h3>User-friendly</h3>
          <p>
            Nestic has been developed to provide an effortless experience to the
            user.
          </p>
          <img src="simple.png" alt="Feature 3 illustration" />
        </div>
      </div>
    </div>
  );
};

export default Features;
