import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./Nav.css";

function Nav() {
  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    <nav>
      <div className="nav-left">
        <Link to="#hero" smooth>
          <h1 className="logo">Nestic</h1>
        </Link>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <img src="MenuIcon.svg" alt="Hamburger Menu" />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li onClick={handleShowNavbar}>
              <Link to="#hero" smooth>
                Home
              </Link>
            </li>
            <li onClick={handleShowNavbar}>
              <Link to="#features" smooth>
                Features
              </Link>
            </li>
            <li onClick={handleShowNavbar}>
              <Link to="#pricing" smooth>
                Pricing
              </Link>
            </li>
            <li onClick={handleShowNavbar}>
              <Link to="#contact-us" smooth>
                Contact
              </Link>
            </li>
          </ul>
          <div className="mobile">
            <Link to="/login">
              <button className="btnFirst">Sign In</button>
            </Link>
            <Link to="/signup">
              <button className="btnLast">Sign Up</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="nav-right">
        <Link to="/login">
          <button className="btnFirst">Sign In</button>
        </Link>
        <Link to="/signup">
          <button className="btnLast">Sign Up</button>
        </Link>
      </div>
    </nav>
  );
}

export default Nav;
