import React from "react";
import { Link } from "react-router-dom";
import "./PricingCard.css";

const PricingCard = () => {
  return (
    <div className="pricing-container">
      <h2>Pricing Plan</h2>
      <p>Here are the main features of Nestic</p>
      <div className="pricing-card">
        <h3>Subscription Plan</h3>
        <div className="pricing">
          <div className="discount">
            <span>15% OFF</span>
            <p>Limited Time</p>
          </div>
          <div className="price">
            <p>N750k</p>
            <span>per year</span>
          </div>
        </div>
        <span>Unlimited access</span>
        <span>24/7 Support</span>
        <span>Free export</span>
        <Link to="/signup">
          <button className="btn-select-plan">Select Plan</button>
        </Link>
      </div>
    </div>
  );
};

export default PricingCard;
