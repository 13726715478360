import React from "react";
import "./HowItWorks.css";

const HowItWorks = () => {
  return (
    <div className="how-it-works">
      <h2>How it works</h2>
      <div className="how-it-works-container">
        <div className="item">
          <img src="svg1.svg" alt="icon representing importation of data" />
        </div>

        <div className="line"></div>
        <div className="item">
          <h3>Import Data</h3>
          <p>
            Import the relevant NECO data containing number centers and number
            of question papers needed in a CSV file format.
          </p>
        </div>
        <div className="item">
          <h3>Process Data</h3>
          <p>
            Input denominator value, and OMR (plus or minus 5) click start and
            watch the magic happen. When it is done, click export.
          </p>
        </div>
        <div className="line"></div>
        <div className="item">
          <img src="svg2.svg" alt="icon representing processing of data" />
        </div>
        <div className="item a">
          <img src="svg3.svg" alt="icon representing exportation of sticker" />
        </div>

        <div className="line"></div>
        <div className="item b">
          <h3>Export Labels</h3>
          <p>
            Click the export button and export all your labels/stickers to
            microsoft word. Its that easy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
