import React from "react";
import { Link } from "react-router-dom";
import "./Hero.css";

function Hero() {
  return (
    <div className="hero">
      <div className="hero-titles">
        <h1 className="hero-title">Creating NECO stickers made easy</h1>
        <p className="hero-subtitle">
          Create stickers for NECO dockets in record time, at a super affordable
          rate
        </p>
      </div>
      <div className="hero-buttons">
        <Link to="/signup">
          <button className="sign-up-button">Sign Up</button>
        </Link>
        <Link to="/login">
          <button className="sign-in-button">Sign In</button>
        </Link>
      </div>
    </div>
  );
}

export default Hero;
